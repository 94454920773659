import React, { Fragment, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useGenericForm } from './hooks/useGenericForm';
import { CircularProgress } from '@material-ui/core';
import GenericDialog from 'components/GenericDialog';
import ThemeButton from 'components/atoms/ThemeButton';
import RadioButton from 'bit/mobiauto.web-ui-components.components.radio-button/RadioButton';
import * as S from './styles';
import { DynamicFieldsForm } from './DynamicFieldsForm';
import useGetProposalStatus from './hooks/useGetProposalStatus';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';

const LoadingComponent = () => {
  return (
    <>
      <div style={{ padding: '11px 0px' }}>
        <Skeleton width="100%" height="20px" variant="rect" animation="wave" />
      </div>
      <div style={{ padding: '11px 0px' }}>
        <Skeleton width="100%" height="20px" variant="rect" animation="wave" />
      </div>
    </>
  );
};

const ConfirmStatusDialog = ({
  isOpen,
  confirmStatus,
  getProposalStatusDetail,
  isCallCenter = false,
  isOem = false,
  draggableItemId,
  departmentId = null,
  onClose,
  clientUserCpfCnpj,
  enableOrderId = false,
  currentProposalId,
  titleModal = `Mover para "Concluído"`,
}) => {
  const { data: status, isLoading } = useGetProposalStatus({
    draggableItemId,
    isCallCenter,
    departmentId,
    isOem,
    currentProposalId,
  });

  const [selectedItem, setSelectedItem] = useState('');

  const { formFields, initialFormValues, validationSchema } = useGenericForm({
    statusList: status?.data,
    selectedStatus: selectedItem,
  });

  const buildSubmitFormData = async (values: any) => {
    const status = selectedItem;

    const customFields = _.mapValues(values, value => {
      return typeof value === 'object' ? value?.value : value;
    });

    await confirmStatus(status, customFields);
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: validationSchema,
    onSubmit: async values => {
      buildSubmitFormData(values);
      onClose();
    },
    enableReinitialize: true,
  });

  const handleConfirmChangeStatus = () => {
    formik.handleSubmit();
  };

  return (
    <GenericDialog
      open={isOpen}
      onClose={onClose}
      title={titleModal}
      maxWidth={'xs'}
      DialogAction={() => (
        <S.LineEnd>
          <ThemeButton
            fullWidth
            onClick={!isLoading && handleConfirmChangeStatus}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size={'1.5rem'} thickness={7} />
            ) : (
              'Concluir'
            )}
          </ThemeButton>
        </S.LineEnd>
      )}
    >
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <Fragment>
          {status?.data.map(element => {
            return (
              <S.StatusWrapper key={element.id}>
                <S.StatusButton
                  key={element?.id}
                  onClick={() => setSelectedItem(element.status)}
                >
                  <S.LineSpaceBetween>
                    <S.Label>{element?.label}</S.Label>
                    <RadioButton checked={selectedItem === element?.status} />
                  </S.LineSpaceBetween>
                </S.StatusButton>

                {selectedItem === element?.status && (
                  <DynamicFieldsForm formik={formik} formFields={formFields} />
                )}
              </S.StatusWrapper>
            );
          })}
        </Fragment>
      )}
    </GenericDialog>
  );
};

export default ConfirmStatusDialog;
