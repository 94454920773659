import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import ButtonBase from '@material-ui/core/ButtonBase';

export const StatusWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const StatusButton = styled(ButtonBase)`
  flex-grow: 1;
  max-width: 100%;
`;

export const Label = styled.div`
  font-family: 'Poppins Regular';
  font-size: 14px;
  color: ${Colors.gray.dark};
`;

export const LineSpaceBetween = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const LineEnd = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Hint = styled.span`
  font-style: italic;
  color: #5f5f5f;
  font-size: 14px;
  align-self: start;
  margin-bottom: -10px;
  &[data-is-error='true'] {
    color: red;
  }
`;

export const DynamicFieldsFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const FieldsWrapper = styled.div``;
