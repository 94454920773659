import * as Yup from 'yup';
import _ from 'lodash';
import {
  ActionFieldType,
  Field,
  FormStatus,
  GenericFormFields,
} from '../utils/interfaces';
import validateCpfCnpj from 'helpers/validateCpfCnpjFnando';

interface Props {
  statusList: FormStatus[];
  selectedStatus: string;
}

export function useGenericForm({ statusList, selectedStatus }: Props) {
  const selectedFormStatus = statusList?.find(
    status => status.status === selectedStatus
  );

  const fields = selectedFormStatus?.fields || [];

  const generateInitialValues = (): GenericFormFields => {
    const initialValues: GenericFormFields = {};

    fields.forEach(field => {
      switch (_.upperCase(field.type)) {
        case ActionFieldType.Select:
          initialValues[field.attribute] = '';
          break;
        case ActionFieldType.Numeric:
          initialValues[field.attribute] = undefined;
          break;
        default:
          initialValues[field.attribute] = field.required ? '' : undefined;
          break;
      }
    });

    return initialValues;
  };

  const generateYupSchema = () => {
    const shape: { [key: string]: Yup.AnySchema } = {};

    fields.forEach(field => {
      let schema: Yup.AnySchema;

      switch (_.upperCase(field.type)) {
        case ActionFieldType.Alphanumeric:
          schema = Yup.string();
          break;
        case ActionFieldType.CPFCNPJ:
          schema = Yup.string().test(
            'cpfCnpjValidation',
            'CPF Inválido',
            (value: any) => (value ? validateCpfCnpj(String(value)) : true)
          );
          break;
        case ActionFieldType.Select:
          schema = Yup.mixed();
          break;
        case ActionFieldType.Numeric:
          schema = Yup.number();
          break;
        default:
          schema = Yup.string();
      }

      schema = field.required
        ? schema.required('Campo obrigatório')
        : schema.optional();

      shape[field.attribute] = schema;
    });

    return Yup.object().shape(shape);
  };

  const generateFormFields = () => {
    return fields.map((field: Field) => ({
      label: field.label,
      name: field.attribute,
      type: _.upperCase(field.type),
      required: field.required,
      placeholder: field.placeholder ? field.placeholder : field.label,
      ...(_.upperCase(field.type) === ActionFieldType.Select && {
        options: field.options || [],
      }),
    }));
  };

  return {
    formFields: generateFormFields(),
    initialFormValues: generateInitialValues(),
    validationSchema: generateYupSchema(),
  };
}
