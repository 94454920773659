import { useMutation } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import fandiServices from 'services/fandiServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import EnvVars from 'helpers/envVars';
import getImage from 'components/getImage';
import { useChatContext } from 'pages/chats/ChatsContext';
import { useState } from 'react';

const domain = EnvVars('API_FANDI_BASE_URL');

const useToObtainGuid = () => {
  const dispatch = useDispatch();
  const {
    dealerStore: { currentDealer },
    dealerStore: { primaryColor },
    dealerStore: { secondaryColor },
  } = useSelector(state => ({
    dealerStore: state.dealerStore,
  }));

  const { currentProposal, otherStates }: any = useChatContext();

  const [downpaymentValue, setDownpaymentValue] = useState<string | number>('');

  const handleValueChange = (values: {
    floatValue: number;
    formattedValue: string;
  }) => {
    setDownpaymentValue(values.floatValue);
  };

  const lastVehicleArray =
    otherStates?.interestProducts[otherStates?.interestProducts.length - 1];

  const vehicle = currentProposal?.vehicle?.find(
    item => item?.id === lastVehicleArray?.id
  );

  const linkImage = getImage(
    {
      imageId: currentDealer?.logo?.imageId,
      imageVcode: currentDealer?.logo?.imageVcode,
    },
    80,
    120,
    120
  );

  const fandiIntegration = currentDealer.externalDealerIdentification.find(
    externalDealer => externalDealer.externalProvider === 'FANDI'
  );

  const vehicleBasePrice = Number(vehicle?.price || 0);

  const initialDownPayment = Number(downpaymentValue || 0);

  const vehicleBasePriceWithowthInitialDownPayment =
    vehicleBasePrice - initialDownPayment;

  const financingPercentage = Number(
    currentProposal?.percentualValueOfFinancingVehiclePrice || 0
  );

  const additionalCostPercentage =
    vehicleBasePriceWithowthInitialDownPayment * (financingPercentage / 100) +
    vehicleBasePriceWithowthInitialDownPayment;

  const additionalValueOfProductsOnFinancing =
    currentDealer?.additionalValueOfProductsOnFinancing || 0;

  const percentWithAdditional =
    additionalCostPercentage + additionalValueOfProductsOnFinancing;

  const totalVehiclePrice = !financingPercentage
    ? vehicleBasePrice - initialDownPayment
    : percentWithAdditional + initialDownPayment;

  const fetchGuidMutation = useMutation(
    async () => {
      const response = await fandiServices.toObtainGuid({
        config: {
          chaveAcesso: fandiIntegration?.externalIdentification?.split(
            '|'
          )?.[1],
          cnpjLoja: currentDealer.cnpj?.replace(/[.-]/g, ''),
          cpfVendedor: '30030030030',
          urlLogomarca: linkImage || null,
          corFundo: '#ffffff',
          corDestaque: primaryColor,
          corFonteBotao: secondaryColor,
          urlCallback: `${domain}/public/api/callback/checkout/quick-simulation?dealerId=${currentDealer?.id}&proposalId=${currentProposal?.id}`,
          // 'https://api.mobiauto.com.br/fandi/public/api/callback/checkout/quick-simulation?dealerId=18371&proposalId=38352388',
        },
        cliente: {
          nome: currentProposal?.clientUserName,
          sexo: null,
          dataNascimento: null,
          possuiCnh: null,
          usoComercial: false,
          pcd: false,
          cpf: currentProposal.clientUserCpfCnpj?.replace(/[.-]/g, ''),
          email: currentProposal?.clientEmail,
          celular: currentProposal?.clientUserPhone,
          usoTaxi: false,
        },
        veiculo: {
          molicar: null,
          codigoParceiro: null,
          tipo: null,
          placa: vehicle?.plate || null,
          valorVeiculo: totalVehiclePrice,
          chassi: vehicle?.vin || null,
          fipe: vehicle?.fipe || null,
          quilometragem: vehicle?.deal0km ? 0 : vehicle?.km,
          zeroKm: vehicle?.deal0km,
          cor: null,
          anoModelo: vehicle?.modelYear || null,
          anoFabricacao: vehicle?.productionYear || null,
        },
        simulacao: {
          valorEntrada: initialDownPayment,
          quantidadeParcelas: null,
        },
        confirmarDados: [
          'ConfirmarDadosPessoais',
          'ConfirmarDadosVeiculo',
          'ConfirmarDadosFinanciamento',
        ],
      });
      return response;
    },
    {
      onError: () => {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            'Ocorreu um erro inesperado.',
            true
          )
        );
      },
    }
  );

  return { fetchGuidMutation, downpaymentValue, handleValueChange };
};

export default useToObtainGuid;
